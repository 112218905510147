import { computed, defineComponent, reactive, ref, watch, onUpdated, onUnmounted } from '@vue/composition-api';
import { useUpdateCityDetailsMutation, useGetAllTagsQuery, useGetCityQuery, useGetCityVenuesQuery } from '@/generated-types/graphql.types';
import EmptyList from '@/GeoPages/components/EmptyList.vue';
import OrganisationsBlock from '@/GeoPages/components/OrganisationsBlock.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import SectionSpaces from '@/Venue/components/SectionSpaces.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import PageFAQ from '@/components/PageFAQ.vue';
import { optimizeImage, useGetLocalizedPath } from '@/util/globalHelpers';
import { SITE_URL } from '@/App.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import useStructuredData from '@/shared/composables/useStructuredData';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    components: {
        EmptyList,
        OrganisationsBlock,
        PageFAQ,
        SaveFooter,
        SectionSpaces,
        TrustPublication
    },
    setup(_, context) {
        const { root } = context;
        const city = ref(undefined);
        const citySeo = reactive({
            h1: '',
            h2: '',
            seoText: '',
            metaTitle: '',
            metaDescription: ''
        });
        const seoUpdatesInProgress = ref(false);
        const cityVenueMainImages = ref([]);
        const cityOrganisations = ref([]);
        const cityVenues = ref([]);
        const faqQuestions = ref([]);
        const extraSeo = ref([]);
        const tag = ref(root.$route.params.tag);
        const seoH1Element = ref(null);
        const seoH2Element = ref(null);
        const seoDescriptionElement = ref(null);
        const allTagsQueryOptions = reactive({
            enabled: !!tag.value
        });
        const { result: allTags } = useGetAllTagsQuery(allTagsQueryOptions);
        const getCityVariables = reactive({
            slug: ''
        });
        const getCityOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch, onResult } = useGetCityQuery(getCityVariables, getCityOptions);
        /**
         * On result callback for get city query with cloning object
         * and creating backup object
         */
        onResult((result) => {
            if (!result?.data?.get_specific_location)
                return;
            city.value = {
                id: result.data.get_specific_location.seo_entity_id,
                title: result.data.get_specific_location.seo_entity_name || '',
                region: result.data.get_specific_location.seo_region_name || '',
                query: {
                    lat: result.data.get_specific_location.latitude,
                    lng: result.data.get_specific_location.longitude,
                    place: result.data.get_specific_location.seo_entity_name || ''
                },
                image: '',
                slug: result.data.get_specific_location.seo_entity_slug || '',
                videoUrl: result.data.get_specific_location.video_url || ''
            };
            citySeo.h1 = result.data.get_specific_location.seo.h1;
            citySeo.h2 = result.data.get_specific_location.seo.h2 || '';
            citySeo.description =
                result.data.get_specific_location.seo.description || '';
            citySeo.metaTitle = result.data.get_specific_location.seo.meta_title;
            citySeo.metaDescription =
                result.data.get_specific_location.seo.meta_description;
            faqQuestions.value =
                result.data.get_specific_location.faqs.map((question) => {
                    return {
                        question: question.question,
                        answer: question.answer
                    };
                }) || [];
            extraSeo.value =
                result.data.get_specific_location.seo_extra.map((extra) => {
                    return {
                        title: extra.title,
                        description: extra.description
                    };
                }) || [];
            useMeta({
                title: citySeo.metaTitle,
                description: citySeo.metaDescription,
                keywords: root.$i18n
                    .t('meta.general.meta_keywords', {
                    returnObjects: true
                })
                    .map((keyword) => `${keyword} ${city.value.title}`)
                    .join(','),
                url: SITE_URL +
                    useGetLocalizedPath(`${GeoPagesRoutes.CITIES}${city.value.slug}`),
                imageUrl: city.value.image || '',
                noIndex: !!tag.value // do not index the page if tag is present
            });
            getCityVenuesVariables.slug = city.value.slug;
            getCityVenuesOptions.enabled = true;
            refetchCityVenues(getCityVenuesVariables);
        });
        const getCityVenuesVariables = reactive({
            slug: '',
            tag: tag.value
        });
        const getCityVenuesOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch: refetchCityVenues, onResult: onCityVenuesResult } = useGetCityVenuesQuery(getCityVenuesVariables, getCityVenuesOptions);
        onCityVenuesResult((result) => {
            if (!result?.data?.get_specific_location)
                return;
            cityVenues.value = result.data.get_specific_location.venues?.result || [];
            const organisations = result.data.get_specific_location.venues?.result.map((venue) => venue && venue.collection);
            const collectionSlugs = organisations.map((el) => el?.slug);
            cityOrganisations.value = organisations.filter((collection, index) => {
                return (collection &&
                    collection.has_public_page &&
                    collectionSlugs.indexOf(collection.slug) === index);
            });
            cityVenues.value.forEach((venue) => {
                if (venue && venue.venue_images.length > 1) {
                    cityVenueMainImages.value.push(...venue.venue_images
                        .filter((image) => image && image.order === 0)
                        .map((image) => {
                        return {
                            plain_image_url: image.image_url,
                            image_url: image.image_url,
                            venue_name: venue.venue_name
                        };
                    }));
                }
            });
            city.value.image = getRandomCityImage();
        });
        const getRandomCityImage = () => {
            const randomCityVenueImage = cityVenueMainImages.value[cityVenueMainImages.value.length > 1
                ? Math.floor(Math.random() * cityVenueMainImages.value.length)
                : 0];
            return randomCityVenueImage?.plain_image_url || '';
        };
        /**
         * Watch city slug and update the city content
         */
        watch(() => root.$route.params, (newParams, oldParams) => {
            if (newParams && newParams.city !== oldParams?.city) {
                const { city, region } = root.$route.params;
                getCityVariables.slug = `/${region}/${city}`;
                getCityOptions.enabled = true;
                refetch(getCityVariables);
            }
        }, { immediate: true });
        const tags = computed(() => {
            return allTags.value?.get_all_tags || [];
        });
        /**
         * Watch locale and update the city content if it's changed.
         * Also set a localized tag to the URL params if it's available.
         */
        watch(() => globalThis.$i18n.locale, (curr, prev) => {
            if (!prev)
                return;
            getCityOptions.enabled = true;
            refetch(getCityVariables);
        }, { immediate: true });
        /**
         * If the page language is changed, update the tag value and URL tag param without the component refresh
         */
        onUpdated(() => {
            const currentVenueTag = tags.value.find(_tag => _tag.nl === tag.value || _tag.en === tag.value);
            tag.value = currentVenueTag?.[globalThis.$i18n.locale];
            if (tag.value) {
                const newPath = root.$route.path.replace(/[^/]*$/, tag.value);
                history.pushState({}, '', newPath);
            }
        });
        const spacesList = computed(() => {
            let spaceList = [];
            cityVenues.value.forEach((venue) => {
                venue?.venue_spaces.forEach((space) => {
                    if (space) {
                        spaceList.push({
                            images: space.space_images.map(image => image && image.image_url),
                            bookingType: space.space_booking_type,
                            venueName: venue?.venue_name,
                            features: space.space_features.map(el => el.feature_id),
                            activities: space.space_categories.map(category => category && category.category_id),
                            spaceName: space.space_name,
                            spaceUrl: space.space_url,
                            spaceId: space.space_id,
                            internalName: space.internal_name,
                            halfDayPrice: space.half_day_price,
                            spaceSize: space.space_size,
                            isAfterpayAllowed: space.is_afterpay_allowed,
                            organisationRating: venue.organisation_rating.score,
                            attendees: {
                                minGuests: space.min_guests,
                                maxGuests: space.max_guests
                            },
                            isHighlighted: space.is_highlighted,
                            isNew: space.is_new,
                            isFavorite: space.is_favorite
                        });
                    }
                });
            });
            return spaceList;
        });
        watch(() => spacesList.value, (newValue, oldValue) => {
            if (newValue.length && !oldValue.length && window.scrollY > 500) {
                root.$scrollTo('#city-page__spaces', 1500, {
                    offset: -100
                });
            }
        }, { immediate: true });
        watch(() => city.value, (newValue, oldValue) => {
            if (newValue && oldValue && newValue?.title !== oldValue?.title) {
                // Send event
                globalThis.$gtm.trackEvent({
                    event: 'set_city',
                    category: 'geo',
                    action: 'city_page_opened',
                    label: newValue?.title,
                    value: newValue?.title,
                    noninteraction: true
                });
            }
        }, { immediate: true });
        /* Admin editing features block start */
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const { mutate } = useUpdateCityDetailsMutation();
        const isFooterSuccessVisible = ref(false);
        /**
         * Shows save footer once something gets changed in the SEO text
         */
        const handleAdminInput = () => {
            seoUpdatesInProgress.value = true;
        };
        /**
         * Handles partial rich text formatting (bold and italic text)
         * @param event
         */
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                switch (event.key.toLowerCase()) {
                    case 'b':
                        event.preventDefault();
                        document.execCommand('bold');
                        break;
                    case 'i':
                        event.preventDefault();
                        document.execCommand('italic');
                        break;
                }
            }
        };
        /* Admin editing features block end */
        /**
         * Check for city fields changes
         */
        const hasChanges = () => {
            return seoUpdatesInProgress.value;
        };
        const onRevertChanges = () => {
            seoH1Element.value.innerHTML = citySeo.h1;
            seoH2Element.value.innerHTML = citySeo.h2;
            seoDescriptionElement.value.innerHTML = citySeo.description;
            seoUpdatesInProgress.value = false;
        };
        const onSaveChanges = () => {
            if (city.value) {
                const input = {
                    city_id: city.value.id,
                    h1: seoH1Element.value.innerHTML,
                    h2: seoH2Element.value.innerHTML,
                    description: seoDescriptionElement.value.innerHTML
                };
                mutate({ input })
                    .then(() => {
                    refetch();
                    seoUpdatesInProgress.value = false;
                    isFooterSuccessVisible.value = true;
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        console.log(graphQLErrors);
                    }
                });
            }
        };
        const onClose = () => {
            isFooterSuccessVisible.value = !isFooterSuccessVisible.value;
            return isFooterSuccessVisible.value;
        };
        const isSaved = () => {
            return isFooterSuccessVisible.value;
        };
        /* Admin editing features block end */
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const schemaSpaceInfo = computed(() => {
            return {
                '@context': 'https://schema.org',
                '@graph': []
            };
        });
        EventBus.$on('onBreadcrumbsReady', data => {
            injectData(data);
        });
        /**
         * Inject structured data to the page when FAQ and Breadcrumbs pages are loaded
         * @param structuredData
         */
        const injectData = (structuredData) => {
            const data = schemaSpaceInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                ...data
            });
        };
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            city,
            cityOrganisations,
            citySeo,
            cityVenues,
            cityVenueMainImages,
            extraSeo,
            faqQuestions,
            seoH1Element,
            seoH2Element,
            seoDescriptionElement,
            spacesList,
            tag,
            user,
            GeoPagesRoutes,
            handleAdminInput,
            handleKeyDown,
            hasChanges,
            injectData,
            isSaved,
            onClose,
            onRevertChanges,
            onSaveChanges,
            optimizeImage,
            useGetLocalizedPath
        };
    }
});
