var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"organisations-block"},[(_vm.organisations && _vm.organisations.length > 5)?_c('vueper-slides',{staticClass:"organisations-slider no-shadow",attrs:{"visible-slides":_vm.visibleSlides,"autoplay":"","bullets":false,"arrows":false,"duration":3000,"touchable":false,"lazy":true,"infinite":""}},_vm._l((_vm.organisations),function(organisation,key){return _c('vueper-slide',{key:key,staticClass:"organisations-slider__item",nativeOn:{"click":function($event){return _vm.goToOrganisationPage(organisation.slug)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('img',{staticClass:"organisations-slider__item-logo",attrs:{"src":organisation.logo && organisation.logo.image_url
              ? ((organisation.logo.image_url) + "?tr=w-100,h-50,cm-pad_resize")
              : '/img/common/logo-placeholder.png',"alt":"Collection image","loading":"lazy","width":100,"height":50}})]},proxy:true}],null,true)})}),1):_vm._l((_vm.organisations),function(organisation,key){return _c('router-link',{key:key,class:[
      'organisations-block__item',
      { last: key === _vm.organisations.length - 1 }
    ],attrs:{"to":_vm.useGetLocalizedPath(
        ("" + (_vm.OrganisationRoutes.ORGANISATION) + (organisation.slug))
      ),"target":"_blank"}},[_c('img',{attrs:{"src":organisation.logo && organisation.logo.image_url
          ? ((organisation.logo.image_url) + "?tr=w-100,h-50,cm-pad_resize")
          : '/img/common/logo-placeholder.png',"alt":"Collection logo","loading":"lazy","width":100,"height":50}})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }