import { defineComponent, computed } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
export default defineComponent({
    props: {
        organisations: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { root } = context;
        const visibleSlides = computed(() => {
            return root.$mq.phone ? 2 : 5;
        });
        const goToOrganisationPage = (slug) => {
            let route = globalThis.$router.resolve({
                path: useGetLocalizedPath(`${OrganisationRoutes.ORGANISATION}${slug}`)
            });
            window.open(route.href, '_blank');
        };
        return {
            visibleSlides,
            OrganisationRoutes,
            goToOrganisationPage,
            useGetLocalizedPath
        };
    }
});
